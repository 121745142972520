import React, { useRef, useCallback, useState } from 'react'
import { useJsApiLoader } from '@react-google-maps/api';
import Map from './components/Map/index.js';
import InputBox from './components/InputBox/index.js'
import MapDrawer from './components/MapDrawer/index.js';
import polyline from '@mapbox/polyline';
import { getPlaces, getPlacesDetails } from './api/methods';

let google;


const center = {
    lat: 43,
    lng: -79
};

const App = () => {
    const [useHighway, setUseHighway] = useState(true);
    const [distance, setDistance] = useState('');
    const [duration, setDuration] = useState('  ');
    const [directions, setDirections] = useState(null);
    const [open, setOpen] = useState(false);
    const [nearData, setNearData] = useState(null);
    const [map, setMap] = useState(null);
    const [windowCoords, setWindowCoords] = useState(null);
    const [firstRoute, setFirstroute] = useState(null);
    const [secondRoute, setSecondroute] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCPQY3x3nJONyTb9xpZv1iBQkAmLV4ZhUs",
        libraries: ["places"],
    })

    const onMapLoad = mapRef => {
        setMap(mapRef);
    };

    const getMiddlePoint = (polylineOverview) => {
        const polylineArray = polyline.decode(polylineOverview);
        const halfIndex = Math.round(polylineArray.length / 2);
        return polylineArray[halfIndex];
    }

    const getWindowCoords = (polylineOverview) => {
        let array = [];
        const polylineArray = polyline.decode(polylineOverview);
        const quarterIndex = Math.round(polylineArray.length * 0.25);
        array.push({array: polylineArray[quarterIndex]});
        const thirdQuarterIndex = Math.round(polylineArray.length * 0.75)
        array.push({array: polylineArray[thirdQuarterIndex]});
        return array;
    }


    const calculateRoute = async (start, end) => {

        console.log(start, end)
        const directionsService = new window.google.maps.DirectionsService()
        const result = await directionsService.route({
            origin: start,
            destination: end,
            travelMode: window.google.maps.TravelMode.DRIVING,
            avoidHighways: !useHighway,
        })
        //setDirections(result);
        //setDistance(result.routes[0].legs[0].distance.text)
        //setDuration(result.routes[0].legs[0].duration.text)
        const halfwayLocation = getMiddlePoint(result.routes[0].overview_polyline)
        const request = {
            location: { lat: halfwayLocation[0], lng: halfwayLocation[1] },
            radius: '50000',
            type: ['lodging']
        };
        let service;
        service = new window.google.maps.places.PlacesService(map);
        service.nearbySearch(request, callback);
        async function callback(results, status) {
            if (status == window.google.maps.places.PlacesServiceStatus.OK) {
                if (results.length) {
                    const location = results[0].geometry.location;
                    const nearPoint = results[0];
                    const directions = await directionsService.route({
                        origin: start,
                        destination: end,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                        avoidHighways: !useHighway,
                        waypoints: [{ location: location }]
                    })
                    
                    console.log("directions", directions)
                    let windowCoords = getWindowCoords(directions.routes[0].overview_polyline);
                    windowCoords[0] = {coords: windowCoords[0], distance: directions.routes[0].legs[0].distance.text, duration: directions.routes[0].legs[0].duration.value}
                    windowCoords[1] = {coords: windowCoords[1], distance: directions.routes[0].legs[1].distance.text, duration: directions.routes[0].legs[1].duration.value}
                    setWindowCoords(windowCoords);
                    setDirections(directions);

                    const request = {
                        placeId: nearPoint.place_id,
                    };

                    service.getDetails(request, callback);
                    function callback(place, status) {
                        if (status == window.google.maps.places.PlacesServiceStatus.OK) {
                            console.log(place, "place")
                            setNearData({
                                name: place.name,
                                start: start,
                                end: end,
                                locality: place.formatted_address,
                                city: place.address_components[2].long_name
                            })
                        }
                    }
                }
            }
        }

    }

    const clearRoute = () => {
        setDirections(null);
        setDistance('');
        setDuration('');
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };



    return (
        <div style={{ position: 'absolute', height: '100vh', width: '100vw' }}>
            {isLoaded ? (
                <>
                    <MapDrawer nearData={nearData} open={open} />
                    <InputBox
                        open={open}
                        handleDrawerClose={handleDrawerClose}
                        handleDrawerOpen={handleDrawerOpen}
                        setUseHighway={setUseHighway}
                        useHighway={useHighway}
                        distance={distance}
                        duration={duration}
                        calculateRoute={calculateRoute}
                        clearRoute={clearRoute}
                    />
                    <Map windowCoords={windowCoords} center={center} directions={directions} onMapLoad={onMapLoad}
                    />
                </>
            ) : <></>}
        </div>
    )
}

export default App;