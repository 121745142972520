import React from 'react'
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const toHoursAndMinutes = (totalSec) => {
    const totalMinutes = Math.floor(totalSec / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}${' '}h${' '}${minutes}${' '}min`;
}
const fontStyle = { marginLeft: "5px", fontWeight: 'bold', fontSize: 14 }

export const InfoBox = ({ coord }) => {
    return (
        <>
            <Box display='flex'>
                <DirectionsCarIcon sx={{ fontSize: 18 }} />
                <Typography sx={fontStyle}>{coord ? coord.distance : ''}</Typography>
            </Box>
            <Box>
                <Box display='flex'>
                    <AccessTimeIcon sx={{ fontSize: 18 }} />
                    <Typography sx={fontStyle}>{coord ? toHoursAndMinutes(coord.duration) : ''}</Typography>
                </Box>
            </Box>
        </>
    )
}
