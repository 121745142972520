import React, { useState, useRef } from 'react'
import Box from '@mui/material/Box';
import { Autocomplete } from '@react-google-maps/api';
import './index.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Input, Checkbox, FormGroup, FormControlLabel } from '@mui/material';

const google = window.google;

const InputBox = ({ calculateRoute, clearRoute, setUseHighway, useHighway, handleDrawerClose, handleDrawerOpen, setHalfMarker }) => {

  const [startValue, setStartValue] = useState("");
  const [endValue, setEndValue] = useState("");

  // const onTextChange = (e) => setStartValue(e.target.value);
  const startRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const endRef = useRef()

  const handleSubmit = () => {
    if (startRef.current.value !== '') {
      calculateRoute(startRef.current.firstChild.value, endRef.current.firstChild.value)
      // getPlaces(endRef.current.firstChild.value);
    }
    handleDrawerOpen();
  }

  const handleClear = () => {
    clearRoute();
    startRef.current.firstChild.value = '';
    endRef.current.firstChild.value = '';
    handleDrawerClose();
  }

  const handleChange = (event) => {
    setUseHighway(event.target.checked);
  };


  return (
    <div className='boxWrapper'>
      <Box>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 3, width: '25ch' }, display: "flex",
          }}
          component="form"
          noValidate
          zIndex='2'
        >
          <Autocomplete>
            <Input
              placeholder='start'
              type="text"
              ref={startRef}
              sx={{"marginRight": 3}}
            />
          </Autocomplete>
          <Autocomplete>
            <Input
              placeholder='end'
              type="text"
              ref={endRef}
            />
          </Autocomplete>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={handleClear}>Clear</Button>
        </Box>
        <FormControlLabel control={<Checkbox checked={useHighway} onChange={handleChange} />} label="Use highway" />

       {/* <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <div>Distance {distance && distance}</div>
          <div>Duration {duration && duration}</div>
        </Box>*/} 
      </Box>
    </div>
  )
}

export default InputBox;