import React from 'react'
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';


const DrawerItem = ({ element }) => {
  return (
    <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', marginBottom: 5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-evenly' }}>
        <Typography variant="subtitle2">
          {element.start}
        </Typography>
        <Box display="flex" alignItems="center" flexDirection="column">
          <ArrowRightAltIcon sx={{ fontSize: 25 }} />
          <Typography variant="caption">{element.firstLegDistance}</Typography>
          <Typography variant="caption">{element.firstLegTime}</Typography>

        </Box>
        <Typography variant="subtitle2">
          {element.city}
        </Typography>
        <Box display="flex" alignItems="center" flexDirection="column">
          <ArrowRightAltIcon sx={{ fontSize: 25 }} />
          <Typography variant="caption">{element.secondLegDistance}</Typography>
          <Typography variant="caption">{element.secondLegTime}</Typography>

        </Box>
        <Typography variant="subtitle2">
          {element.end}
        </Typography>
      </Box>
      <Divider flexItem />
      <Box sx={{ marginTop: 2 }}>{element.name}</Box>
      <Typography variant='body2' sx={{ marginTop: 2 }}>{element.locality}</Typography>

      <Box></Box>
    </ListItem>
  )
}

export default DrawerItem;
