import React from 'react'
import { GoogleMap, DirectionsRenderer, InfoWindow } from '@react-google-maps/api';
import { InfoBox } from './InfoBox';
import './index.css';
import mapStyles from '../mapStyles';



const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
}

const containerStyle = {
    width: '100vw',
    height: '100vh'
};






const Map = ({ center, directions, onMapLoad, windowCoords }) => {
    console.log(windowCoords)
    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={8}
            options={options}
            onLoad={onMapLoad}
        >
            {/* halfMarker && <Marker position={{ lat: halfMarker[0], lng: halfMarker[1] }} /> */}
            {directions && <DirectionsRenderer directions={directions} />}
            {windowCoords && windowCoords.length > 0 && windowCoords.map(coord =>

            (<InfoWindow position={{ lat: coord.coords.array[0], lng: coord.coords.array[1] }}>
                <InfoBox coord={coord} />
            </InfoWindow>)
            )}
        </GoogleMap>
    )
}

export default Map;