import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import DrawerItem from './DrawerItem';


const MapDrawer = ({ open, nearData }) => {
    const array = nearData ? [nearData] : null;
    const drawerWidth = 400;

    return (
        <Box>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}>
                <List>
                    {array && array.map(element => <DrawerItem element={element} />)}
                </List>
            </Drawer>
        </Box>
    )
}

export default MapDrawer;